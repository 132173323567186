<template>
  <portal to="dialog">
      <div class="dialogbox" >
        <div class="none_overlay">
          <div id="idDialogContent" class="content" style="background: #edf4f7;">
            <div id="id_Dialog_title" class="dialog_title">{{title}}</div>
            <button class="close" @click="close" :title='lang["zakrit"]'>
              <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <line x1="1" y1="15" x2="15" y2="1" stroke="currentColor" stroke-width="2"></line>
                <line x1="1" y1="1" x2="15" y2="15" stroke="currentColor" stroke-width="2"></line>
              </svg>
            </button>
            <div class="clearfix"></div>
            <div class="paper">
              <slot name="default"></slot>
            </div>
            <div class="footer_buttons d-flex" >
              <slot name="buttons"></slot>
            </div>
          </div>
        </div> 
      </div>
  </portal>
</template>
<script>
/* eslint-disable */ 
import { keyevent } from '@/main.js';
import { scroll } from "@/main.js"; //EventBus событий скролла
import { store } from "@/store.js";
import { handleResize } from "@/main.js";
import { webviewCheck } from '../js/webviewCheck.js';
export default {
  data(){
    return {
      component_id:null,
      lastHeight: 0,
      minheight: 0,
      maxheight: 0,
      trueTest: true,
      time:0,
      resizeId: ''
    }
  },
  props: ['title'],
  methods:{
    androidFix(id) {
      const idDialog = document.querySelector('dialogbox');//paper надо менять высоту
      try {
        if(Android != null) {
          
          const androidBrov = Android.getDisplayCutoutTop();
          const btnfoot = document.querySelector('.footer_buttons');
          const paper = document.querySelector('.paper');
          let s;
          if (window.devicePixelRatio > 1.0) {
              s = androidBrov / window.devicePixelRatio;
          }
          if (androidBrov != 0) {
            id.style.paddingTop = s + 'px';
            btnfoot.style.paddingBottom = s + 'px';
            paper.style.height =  `calc(100% - 142px - ${ s }px)`;
          } else {
            id.style.paddingTop = '0px';
            btnfoot.style.paddingBottom = '0px';
            paper.style.height =  `calc(100% - 142px)`;
          }
        }
      } catch(err) {}
    },
    close(){
        this.$emit('close');
      
    },
    handleDialogScroll: function(e, a) {
      try{
        if(document.getElementsByClassName('dialog-filters')){
          //Событие создано для того чтобы на телефонах не отображать постраничную навигацию
          try {
            scroll.$emit('scrolling', e, a);
          } catch (err) {
            //Возникла какая-то ошибка по скроллу
          }
        }
      } catch (err) {
        //
      }
    },
    handleResize() {
    //Эта функция вызывается после того как у нас поменялся размер она и мы заганяем размеры в глобальное реактивное хранилище.
    //Реактивное хранилище позволяет автоматически иницилизировать обновление связанного с этим контента
    this.androidFix(this.resizeId);
    if(this.time) clearTimeout(this.time);
      this.time = setTimeout(function() {
        handleResize.$emit('handleResize');
      }, 500);
    },
  },
  updated() {
    this.androidFix(this.resizeId);
    keyevent.$off('esc');
    keyevent.$on('esc', () => {
      if (!document.getElementsByClassName('modal-mask')[0]) {
        try{
          let lastDialog = store.dialogComponent.opened.length-1;
          store.dialogComponent.opened[lastDialog]();
        }catch(err){
          //
        }
      }    
      this.$emit('close')
    });
  },
  created(){
    this.$nextTick(() => {
      const idDialogA = document.getElementById('idDialogContent');
      this.resizeId = idDialogA;
      this.androidFix(this.resizeId);
    }); 

    //Починка бага на ios
    
    // document.getElementById("content").classList.remove("scroll");
    window.addEventListener('resize',this.handleResize);
    
  },
  beforeDestroy() {
    this.buttonAnimate = false;
    //Починка бага на ios
    // document.getElementById("content").classList.add("scroll");
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    try{
      store.dialogComponent.opened.splice(this.component_id, 1);
    }catch(err){
      //console.error(err);
    }
  },
  computed:{
    lang() {
        return window.lang; //Импортируем в шаблон
    },
    iosBackButton(){
      try{
        if(webviewCheck() == "ios_webview"){
          return true;
        }else{
          return false;
        }
      }catch(err){
        return false;
      }
    },
  }
}
</script>
<style>
.d-flex {
  display: flex;
}

.dialogbox {
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
  background: rgba(0, 0, 0, 0.4);
}

.none_overlay {
  height: 100%;
  z-index: 9999;
}

.dialogbox, .none_overlay {
  display: block;
  position: fixed;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
}

@keyframes loaderT {
  0% {
    opacity: 0;
    bottom: -100px;
  }
  50% {
    opacity: 00.25;
    bottom: -50px;
  }
  100% {
    opacity: 1;
    bottom: 15px;
  }
}

.footer_buttons{
  position: fixed;
  padding: 5px;
  bottom: 15px;
  left: 15px;
  width: calc(100% - 30px);
}

.none_overlay .content {
  background: #fff !important;
  width: 100%;
  height: 100%;
  /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: rgba(255, 255, 255, 0.95);*/
}

@media only screen and (min-width: 1400px) {
   .none_overlay .content {
    width: 75%;
    /* height: 100%; */
    margin: 0 auto;
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.24);
    background: rgba(255, 255, 255, 0.95); */
  }
  .footer_buttons{
    /* position: fixed;
    padding: 5px;
    display: flex;
    bottom: 15px; */
    left: calc(12.5% + 15px);
    width: calc(75% - 30px);
  }
}

.dialogbox .close {
  cursor: pointer;
  font-size: 16pt;
  margin: 15px;
  padding: 17px;
  float: right;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.close svg {
  stroke: rgb(130, 130, 130);
  transition: stroke 0.2s;
}

.close:hover svg {
  stroke: #5a6268;;
}

.dialogbox .paper {
  height: 100%;
  overflow-y:auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

@media screen and (min-width: 710px) {
  .dialogbox .paper {
    height: calc(100vh - 158px);
    background-color: #fff;
    margin: 0 10px 60px 10px;
    border-radius: 5px;
  }

  .dialogbox, .none_overlay{
    padding:10px;
  }
}

@media screen and (max-width: 709px) {
  .dialogbox .paper {
    height: calc(100% - 142px);
    background-color: #fff;
    margin: 0 10px 10px 10px;
    border-radius: 5px;
    max-width: 100vw !important;
  }

  .footer_buttons{
    bottom: 5px;
    left: 5px;
    width: calc(100% - 10px);
  }

  @keyframes loaderT {
    0% {
      opacity: 0;
      bottom: -100px;
    }
    50% {
      opacity: 00.25;
      bottom: -50px;
    }
    100% {
      opacity: 1;
      bottom: 5px;
    }
  }
}
.dialogbox .last{
  margin-top: 0.375rem;
}

.dialog_title{
  max-width: calc(100% - 80px);
  font-size: 16pt;
  padding: 24px 0 24px 24px;
  float: left;
  white-space: nowrap; /* Отменяем перенос текста */
  overflow: hidden; /* Обрезаем содержимое */
  text-overflow: ellipsis;
}

/*Только айфон 10, 11*/
@media only screen and
(device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3), /*X, XS, 11pro, 12 Mini, 13 mini*/
(device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 3), /*XS Max, 11 Pro Max*/
(device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 2), /*XR, 11*/
(device-width : 428px) and (device-height : 926px) and (-webkit-device-pixel-ratio : 3), /*12 Pro Max. 13 pro max, 14 plus*/
(device-width : 390px) and (device-height : 844px) and (-webkit-device-pixel-ratio : 3), /*12, 12 Pro, 13, 13 pro, 14*/
(device-width : 393px) and (device-height : 852px) and (-webkit-device-pixel-ratio : 3), /*14 pro*/
(device-width : 430px) and (device-height : 932px) and (-webkit-device-pixel-ratio : 3) /*14 pro max*/
{
  @media screen and (orientation: portrait){
    .ios-class .footer_buttons{
      padding-bottom: calc(env(safe-area-inset-bottom) - 5px) !important;
    }

    .ios-class .dialogbox .paper {
      height: calc(100vh - 160px - env(safe-area-inset-bottom));
      position: relative;
    }
    .ios-class .footer_buttons {
      position: relative;

    }

    .ios-class .content{
      padding-top:calc(env(safe-area-inset-top) - 20px) !important;
      padding-bottom:10px;
    }
  }
}

@media only screen and
(device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3), /*X, XS, 11pro, 12 Mini, 13 mini*/
(device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 3), /*XS Max, 11 Pro Max*/
(device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 2), /*XR, 11*/
(device-width : 428px) and (device-height : 926px) and (-webkit-device-pixel-ratio : 3), /*12 Pro Max. 13 pro max, 14 plus*/
(device-width : 390px) and (device-height : 844px) and (-webkit-device-pixel-ratio : 3), /*12, 12 Pro, 13, 13 pro, 14*/
(device-width : 393px) and (device-height : 852px) and (-webkit-device-pixel-ratio : 3), /*14 pro*/
(device-width : 430px) and (device-height : 932px) and (-webkit-device-pixel-ratio : 3) /*14 pro max*/
  {
  @media screen and (orientation: portrait) {
    .ios-class .dialogbox .paper {
      height: calc(100vh - 170px - env(safe-area-inset-bottom));
      position: relative;
    }
    .ios-class .footer_buttons {
      position: relative;
    }
    .ios-class .content{
      padding-top:calc(env(safe-area-inset-top) - 30px) !important;
      padding-bottom:10px;
    }
  }
}

@keyframes loader {
  0% {
    opacity: 0;
    margin-top: -1600px;
    /* transform: scale(0); */
  }
  100% {
    opacity: 1;
    margin-top: 0;
    /* transform: scale(1); */
  }
}

@keyframes head_btn_add-in {
  0%{
    opacity: 0;
    margin-top: -1600px;
    transform: scale(1);
    background: rgba(0, 0, 0, 0);
  }
  100%{
    opacity: 1;
    margin-top: 0;
    transform: scale(1);
    background: rgba(0, 0, 0, 0);
  }
}
</style>