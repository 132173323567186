<template>
    <bigmodal @close="closeWindow()" :title="lang['MyReferrals']">
        <div class="ref-container">
            <h5>{{ lang['EarnWithUs'] }}</h5>
            <hr>
            <p>{{ lang['RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.'] }}</p>
            <div class="blocklink">
                <label for="link">{{ lang['ReferralLink'] }}</label>
                <div class="ref-div">
                    <input type="text" name="link" class="form-control" ref="input_link" readonly v-model="ref.ref_link">
                    <button class="btn btn-primary" @click="copyText">
                        <span class="icon-copy-3"></span>
                    </button>
                </div> 
            </div>
            <hr>
            <h5>{{ lang['HowItWorks'] }}</h5>
            <p>{{ lang["ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou."] }}</p>
            <span class="span-grey">{{ lang['TheMinimumWithdrawalAmountIs100USD'] }}</span>
        </div>
        
        <!-- таблица рефералов -->
        <div v-if="ref.referrals && ref.referrals.length > 0">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{ lang['Phone'] }}</th>
                        <th scope="col">{{ lang['Date'] }}</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- пока что убран клик для запросса данных по выбранному рефералу -->
                    <tr v-for="(item, index) in ref.referrals" :key="index"  class="tr-hover"><!-- @click="getMoreInfo(item.account_id)" -->
                        <td>{{ index+1 }}</td>
                        <td>{{ item.phone }}</td>
                        <td>{{ item.register_date }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Модалка на будущее -->
        <morerefdata v-if="showMoreData" :accId="accId" @close="showMoreData = false" />

        <template v-slot:buttons>
            <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
        </template>
    </bigmodal>
</template>

<script>
import bigmodal from '../modals/bigModal';
import morerefdata from './moreRefData';
import { regAPi } from '@/API.js';
import { store } from "@/store.js";

const apiList = new regAPi();
export default {
    components: {
        bigmodal,
        morerefdata,
    },
    data() {
        return {
           accId: null,
           showMoreData: '',
           ref: {}
        }
    },
    created() {
        this.getData();
    },
    methods: {
        closeWindow() {
            this.$emit('updateData')
            this.$emit('close')
        },
        // кнопка копирующая в буфер обмена ссылку
        copyText() {
            this.$refs.input_link.select();
            document.execCommand('copy');
            window.say.message(this.lang['Copied']);
        },
        getMoreInfo(e) {
            this.accId = e;
            this.showMoreData = true;
        },
        getData(){
            try {
                //берем данные о рефералах по апи
                apiList.myReferrals(store.token).then(resp => {
                    if (resp.data.status == 'done') {
                        this.ref = resp.data.data;
                    } else {
                        window.say.messageError(this.lang['Error'])
                    }
                })
            } catch(err) {
                console.log(err)
            }
        },
    },
    computed: {
        lang() {
            return window.lang;
        },
    }
    
}
</script>

<style scoped>
    .btn-secondary{
        width: 100%
    }
    .span-grey{
        color: gray; 
        font-size: 9pt;
    }
    .btn-primary{
        border-radius: 0 0.25rem 0.25rem 0;
    }
    .form-control{
        margin: 0 !important; 
        border-radius: 0.25rem 0 0 0.25rem;
    }
    .ref-div{
        display: flex;
    }
    .ref-container{
        padding: 15px;
    }
    .blocklink {
        background: #e4f1db;
        border-color: #badba4;
        color: #466e2c;
        padding: 8px 10px 8px 14px;
        margin-bottom: 20px;
        text-shadow: 0 1px 0 hsla(0,0%,100%,.2);
        border: 1px solid #dcdcdc;
        border-radius: 5px;
    }
    .tr-hover {
        cursor: pointer;
        transition: 0.5s;
    }
    .tr-hover:hover {
        background: #def9ff;
    }

    .hostApi {
      justify-content: center;
      align-items: center;
      gap: 5px
    }

    .hostApi button{
      margin-top: -16px!important;
    }

    .d-flex {
      display: flex;
    }
</style>