<template>
  <div id="person" :class="{ 'blurred': isBlurred }" v-if="form != null">
    <headtop ref="head" :personData="form" :withoutName="withoutName" @updateData="getData()" />

    <div class="main-div" v-if="form != null" ref="thisPerson">
      <div class="container">
        <!--
            Уведомление которое помогает осознать что это за страница
        -->
        <div v-if="lang['startDescription'] && form.companies.length > 0 && startMessage === true"
             class="alert alert-warning" role="alert">
          <strong>{{ lang["startDescription"] }}</strong>
          <button class="closeStartDescription p-absolute" @click="closeStartMessage" :title="lang['closeStartDescription']">
            <span aria-hidden="true" class="icon-cancel-circle"></span>
          </button>
        </div>
        <!--
            Сортировка компаний
        -->
        <div class="d-flex companies">
          <h5 class="h5-500 flex-grow-1" v-if="form.companies.length >= 1">
            {{ lang['MyCompany'] }}
          </h5>
          <template v-if="form.companies.length > 1">
            <b-button class="sortbutton" v-if="!sortList" variant="outline-primary" size="sm" :title="lang['changeTheOrder']"
                      @click="changeCompaniesList">
              <img src="@/assets/saveSVG.svg" class="save-svg" alt="Save Button"/>
            </b-button>
            <b-button class="editbutton" v-if="!sortList" variant="outline-primary" size="sm" @click="settingsDropdown">
              <img src="@/assets/editSVG.svg" class="save-svg" alt="Edit Button"/>
            </b-button>
            <b-button v-if="sortList" size="sm" @click="cancelChanges" class="rollBack" :title="lang['cancelChangeOrder']">
              <img src="@/assets/rollBackSvg.svg" class="save-svg" alt="Rollback Button"/>
            </b-button>
            <b-button class="sortSave" v-if="sortList" variant="success" size="sm" @click="saveCompaniesList" :title="lang['saveChangeOrder']">
              <img src="@/assets/sortSaveSvg.svg" class="save-svg" alt="Rollback Button"/>
            </b-button>
          </template>
        </div>

        <!--Поиск компаний-->
        <div class="company-search" v-if="form.companies.length > 5 && !sortList">
          <input v-model="searchString" type="text" class="form-control searchInput" :placeholder="searchText" :aria-label="lang['Search']">
          <div class="icon-cancel-circle clear-search p-absolute" v-if="searchString != ''" @click="searchString = ''">
          </div>
        </div>
        <div class="not-search-div" v-if="searchString !== '' && companiesList.length <= 0">
          {{ lang["CompanyNotSearch"] }}
        </div>

        <!-- БЛОКИ КОМПАНИЙ -->
        <template v-if="form.companies.length > 0">
          <draggable class="row company-block d-flex" v-model="form.companies" v-bind="dragOptions">
            <div v-for="(item, index) in companiesList" :key="index" class="col-md-4">
              <div class="pos-style" :class="{ 'blocked': item.blocked }" @click="goToCompany(item)">
                   <!--:style="item.blocked ? 'background-color: #f8d5d6;' : 'background: #fafafa;'"-->
                <div class="d-flex">
                  <div class="image-container" :class="sortList ? 'sortedMode' : ''">
                    <img v-if="item.logo.length > 1" class="logoImg"
                         :src="logoUrl + item.company_id + '/' + item.logo" alt="logo">
                    <img v-else :src="defaultLogo" class="logoImg" alt="default logo">
                  </div>
                  <div class="desc-div" :class="sortList ? 'sortedMode' : ''">
                    <h6 class="company_h">{{ item.company_name }}<span
                        v-if="isDeveloper"> [{{
                        item.company_server
                      }}]</span></h6>
                    <p class="description"
                       :style="item.company_description == '' ? { 'color': '#b7b6b6', 'font-size': '0.8rem' } : {}">
                      {{
                        item.company_description != '' ? item.company_description :
                            lang['NoDescriptionSpecified']
                      }}
                    </p>
                  </div>
                </div>
                <div v-if="item.date_of_create" :class="sortList ? 'sortedMode' : ''">
                  <p class="p-style">{{ lang['DateCreate'] + ' ' + item.date_of_create }}<br></p>
                </div>
                <div :class="['buttonCard', 'd-flex', sortList ? 'sortedMode' : '']">
                  <button v-if="lang['EnterToCompany']"
                          :class="item.blocked ? 'btn btn-secondary enter-company-blocked' : 'btn btn-success enter-company'"
                          :disabled="item.blocked">
                    <div class="text-div">{{ lang["EnterToCompany"] }}</div>
                    <div class="img-div">
                      <img src="../../assets/arrow_dark_grey.svg" class="img-arrow" alt="Img Arrow">
                    </div>
                  </button>
                  <!--
                      Иконка с домиком еслю юзер сам создал компанию
                      Иконка БЛОК если юзера сделали не активным в админке SkyService
                  -->
                  <span v-if="item.person_admin" class="icon-home p-absolute"></span>
                  <span v-if="item.blocked" class="icon-blocked p-absolute"></span>
                </div>
                <div v-if="sortList" class="sortedIcon p-absolute"></div>
              </div>
            </div>
          </draggable>
        </template>
        <!--
            Если компаний нет
        -->
        <div v-else class="ghost-container d-flex">
          <div class="ghost-img">
            <img src="@/assets/surprise.svg" class="cat-svg" alt="Surprise Image"/>
          </div>
          <div class="ghost-text d-flex">
            <h5>{{ lang["YouDontHaveCompanyYet"] }}!</h5>
            <div>
              <p class="noCompany-text"
                 v-html="form.user.can_create_company ? lang['NoCompanyText'] : lang['NoCompanyCreateText']">
              </p>
            </div>
            <button v-if="form.user.can_create_company" class="btn btn-outline-dark buttonStartComp"
                    @click="addCompany = !addCompany">{{ lang['CreateNewCompany'] }}
            </button>
            <button style="display: none;" v-if="form.invitations.length <= 0"
                    class="btn btn-danger buttonStartComp" @click="goDemo">{{ lang['visitDemo'] }}
            </button>
          </div>
        </div>
        <!--
           Кнопка создать новую компанию
        -->
        <div class="row">
          <div class="span-container" v-if="form.companies.length >= 1 && form.user.can_create_company">
            <span class="add-company" @click="addCompany = !addCompany">{{ lang['CreateNewCompany'] }}</span>
          </div>
        </div>
        <!--
           Список инвайтов в компанию
        -->
        <h5 class="h5-500" v-if="form.invitations.length >= 1">
          {{ lang['Invites'] }}
        </h5>
        <div class="row company-invites-block">
          <div v-for="(item, index) in  form.invitations" :key="index" class="card">
            <div class="pos-style invites-accept" @click="acceptCompany(item)">
              <div class="d-flex">
                <div class="image-container">
                  <img v-if="item.logo.length > 1" class="logoImg"
                       :src="logoUrl + item.company_id + '/' + item.logo" alt="logo">
                  <img v-else :src="defaultLogo" class="logoImg" alt="default logo">
                </div>

                <div class="invites-div-desc">
                  <h6 class="company_h">{{ item.company_name }}</h6>
                  <p class="description"
                     :style="item.company_description == '' ? { 'color': '#b7b6b6', 'font-size': '0.8rem' } : {}">
                    {{
                      item.company_description != '' ? item.company_description :
                          lang['NoDescriptionSpecified']
                    }}<br></p>
                </div>
              </div>

              <div v-if="item.date_of_create">
                <p class="p-style">{{ lang['DateCreate'] + ' ' + item.date_of_create }}<br></p>
              </div>
            </div>
          </div>
        </div>
        <!--Блок для разробників початок-->
        <div v-if="isDeveloper" class="row">
          <div class="col-md-4" v-if="!sortList">
            <div class="pos-style-developer">
              <div class="d-flex">
                <div class="image-container">
                  <img  :src="developerLogo" class="logoImg" alt="default logo">
                </div>
                <div class="desc-div">
                  <h6 class="company_h">Developer Mode</h6>
                  <p class="description" >
                    <input type="text" v-model="developerURL">
                    <br>
                  </p>
                </div>
              </div>

              <div>
                <p class="p-style">Skyservice with concern for developers<br></p>
              </div>
              <div class="d-flex buttonCard">
                <button class="btn btn-info btn-outline-dark enter-company" @click="goToDeveloperURL()">
                  <div class="text-div">Go to URL</div>
                  <div class="img-div">
                    <img src="../../assets/arrow_dark_grey.svg" class="img-arrow" alt="Img Arrow">
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--Блок для разробників кінець-->
      </div>
    </div>
    <!--
        если у юзера отсутствует email при после прохождения регистрации
    -->
    <addemail v-if="showAddEmail" @close="showAddEmail = false, getData()"/>
    <!--
        Принять или отклонить приглашение в компанию
    -->
    <acceptInvite v-if="showAcceptInvite" :companyData="companyData" @close="showAcceptInvite = false, getData()"/>
    <!--
        Добавление компании, если вдруг у юзера нет пароля, там появляется кнопка настроек
    -->
    <addcompany v-if="addCompany" @close="addCompany = false, getData()" :havePassword="form.user.have_password"
                @openSettings="openSettings"/>
    <!--
        Создание компании при старте
    -->
    <wontCreateCompany v-if="wontCreateCompany" @close="wontCreateCompany = false" @goNext="goNext"
                       @add_mail="addMailInStart"/>
    <!--
        Добавить пароль
    -->
    <addPassword v-if="showAddPass" :passwordText="lang['FirstCreateAnAccountPassword']" @close="showAddPass = false"
                 @passwordReady="openModalSelector"/><!-- , getData() -->
    <!--
        Выбор роли
    -->
    <selectRole v-if="showSelectRole" @selectRole="selectRole"/>
    <!--
        Настройки
    -->
    <personsettings v-if="showSettings" @close="closeSettings"/>
    <!--
        Компонент кнопки для вызова чата на старых андроидах
    -->
    <CrispChatButton v-if="showCrispButton" @close="showCrispButton = false"/>
  </div>
</template>

<script>
import {store} from '@/store.js';
import headtop from '../heads/Head.vue';
import addemail from './addEmail.vue';
import addcompany from './AddCompany.vue';
import acceptInvite from './acceptInvite.vue';
import personsettings from '../settings/PersonSettings.vue';
import draggable from 'vuedraggable';
// модалки которые нужны при старте юзера
import wontCreateCompany from './wontCreateCompany.vue';
import addPassword from '../settings/addPassword.vue';
import selectRole from './selectRole.vue';
import jsSearch from "@/components/jsSearch.js"
import CrispChatButton from "@/components/CrispChatButton.vue" // компонент нативной кнопки чата для старых андроидов
import {webviewCheck} from '../js/webviewCheck.js';
import {ws} from '@/socket/websocket'
import {MoreURL} from "@/API.js";
import defaultLogo from "@/assets/default-logo.png";
import developerLogo from "@/assets/settings.png"

const moreURLs = new MoreURL()

//модуль управления куками
import Cookies from 'js-cookie';
// создаем апи куков с настройкой дэфолтного домена для продакшена
let apiCookies = Cookies;
if (process.env.NODE_ENV == 'production') {
  apiCookies = Cookies.withAttributes({domain: ".skyservice.online"})
}

import {regAPi} from '@/API.js';

const apiList = new regAPi();


let developers = [
  'f2ab140d-f92c-47b2-8c64-b9dd3ab098d8', //Вадим
  '20774647-8e97-4626-9bd4-3d4e326c3aa2', //Илюха
  'bf9e3616-d5e1-44e4-8761-9d838baa96c6', //Димас
  '7289e1c7-1e8a-4975-898a-965f1328d952', //Олег 2
  'fc9b7c2d-bb8f-4e30-b0c1-3b0a32b38025'  //Настя
]

export default {
  name: 'Person',
  props:['personData'],
  components: {
    addemail,
    addcompany,
    acceptInvite,
    headtop,
    personsettings,
    wontCreateCompany,
    addPassword,
    selectRole,
    draggable,
    CrispChatButton,
  },
  data() {
    return {
      logoUrl: 'https://img-1.skyservice.pro/uploads/images/',
      searchString: "",
      form: null,
      companyData: null,
      addCompany: false,
      withoutName: false,
      sortList: false,
      startMessage: true,
      wontCreateCompany: false,
      startLoader: false,
      showSettings: false,
      showAcceptInvite: false,
      showAddPass: false,
      showSelectRole: false,
      showAddEmail: false,
      showCrispButton: false,
      isDeveloper: false,
      developerURL: "http://localhost:1024",
      isBlurred: false,
      phrasesFlag: false,
      defaultLogo,
      developerLogo
    }
  },
  created() {
    //если стартовое сообщение уже закрывали то прячем его
    if (localStorage["closeStartMessage"]) {
      this.startMessage = false;
    }

    //Включать режим разработки
    if (localStorage["isdeveloper"] == 1) {
      this.isDeveloper = true;
    }

    //Берем сохраненную ссылку и вставляем в поле ввода
    if (localStorage["developerURL"]) {
      this.developerURL = localStorage["developerURL"];
    }

    if (webviewCheck() == "android_webview") {
      this.showCrispButton = true
    } else {
      // стартуем чат поддержки
      window.$crisp.push(['do', 'chat:show']);
    }
    //Адаптация чата для мобильно приложения ios iphone 10+
    //Определяем что за девайс, есть ли брова
    try {
      //Notch - вырез в экране айфона
      let isNotch = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sat"));
      if (isNotch > 0 && webviewCheck() == "ios_webview") {
        // Знаходимо перший елемент з атрибутом [data-mode][data-helpdesk-collapsed] (шапка чату) та задаємо стилі
        let firstElement = document.querySelector('.crisp-client #crisp-chatbox [data-mode][data-helpdesk-collapsed]');
        firstElement.parentElement.style = `padding-top: ${isNotch}px !important; background: #5db85b !important;`;
        // Задаємо стилі для елементу (тіла) чату
        document.querySelector('.crisp-client #crisp-chatbox [data-has-health][data-has-drop-zone][data-has-feedback] [data-visible]').style = `margin-bottom: ${isNotch}px !important;`
      }
    } catch (err) {
      console.log("нету моноброви");
    }
    // фикс для приложения почему-то скролилось ниже но в сафари не работает
    window.scrollTo(0, 0);

    // если юзер не авторизирован, перекидываем его на странице логина, если он авторизировался и получил токен, тогда вызываем getData
    console.log(store.token)
    if (!store.token) {
      // редиректим на авторизацию
      document.location.href = 'https://auth.skyservice.online/';
    } else {
      this.getData();

      // если юзер зарегался по рефералке, при входе в акк кикаем рефералку
      if (localStorage.getItem('ref_id') != null) {
        localStorage.removeItem('ref_id');
      }
      // проверяем, стартовала ли регистрация, если это его первый вход с реги, кикаем флаг
      if (localStorage.getItem('registration_start') != null) {
        localStorage.removeItem('registration_start');
      }
      // убираем почту или мыло когда юзер с логина перескакивал на регу
      if (localStorage.getItem('go_reg_phone') != null) {
        localStorage.removeItem('go_reg_phone');
      }
      if (localStorage.getItem('go_reg_mail') != null) {
        localStorage.removeItem('go_reg_mail');
      }
    }

  },
  methods: {
    settingsDropdown() {
      const s = document.querySelector('.dropdown-menu');
      s.classList.remove('show');
      this.showSettings = !this.showSettings;
    },
    startPrepareCompany(status) {
      if (status === 'end') {
        store.loaderText = '';
        store.loader = this.isBlurred = false;
        this.phrasesFlag = false;
        this.secondCallFlag = false;
        return;
      } else {
        this.phrasesFlag = true
        const outputPhrases = (index) => {
          let delay = index === 0 ? 2000 : 3000;
          if (index < this.phrasesArray.length) {
            store.loader = this.isBlurred = true;
            store.loaderText = this.phrasesArray[index];
            setTimeout(() => {
              outputPhrases(index + 1);
            }, delay);
          }
        };
        outputPhrases(0);
      }
    },
    goToDeveloperURL() {
      localStorage["developerURL"] = this.developerURL;
      document.location.href = this.developerURL;
    },
    closeStartMessage() { // закрыть стартовый алерт
      this.startMessage = false;
      localStorage["closeStartMessage"] = true;
    },
    goDemo() { // дємо компания
      location.href = moreURLs.goDemo(store.lang);
    },
    openModalSelector() { // открыть модалку выбора сотрудника\владельца
      if (this.form.invitations.length <= 0) {
        this.showSelectRole = true
      }
    },
    selectRole(obj) { // выбор сотрудника\владельца
      if (obj.accountType == "owner" && obj.createCompany == true) {
        this.showSelectRole = false
        this.startPrepareCompany('start')
      }
      apiList.accountType(obj).then(resp => {
        if (obj.accountType == "owner" && obj.createCompany == true) {
          document.location.href = resp.data.data;
          this.startPrepareCompany('end')
        } else {
          this.showSelectRole = false
          this.getData();
        }

      })
    },
    addMailInStart() { // добавляем почту пользователя
      if (this.form.email == '') {
        this.showAddEmail = true;
      }
    },
    goNext() { // при создании первой компании, сразу летим в неё
      try {
        // this.startLoader = true;
        apiList.companyStart({token: store.token, lang: this.form.lang}).then(resp => {
          // this.startLoader = false;
          if (resp.data.status == 'done') {
            location.href = resp.data.data;
            // this.getData();
          } else {
            this.getData();
            window.say.messageError(this.lang['Error'])
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    closeSettings() { // Закрытие настроек
      this.withoutName = false
      this.showSettings = false;
      this.getData();
    },
    openSettings() { // открытие настроек
      this.showSettings = !this.showSettings;
    },
    // Тут при клике мы берем id компании, токен
    // отправляем это на БЭК
    // С БЭКА получаем сформированную ссылку и летим по ней в админку
    goToCompany(e) {
      if (e.blocked) {
        window.say.messageError(this.lang['companyBlocked'])
      } else if (this.sortList) {
        console.log("В режиме сортировки вход в компанию не разрешен")
      } else {
        this.closeStartMessage();
        try {
          if (e.is_new) {
            console.warn(e)
            this.startPrepareCompany('start')
          }
          apiList.redirectPerson({token: store.token, company_id: e.company_id}).then(resp => {
            console.log(resp)
            if (e.is_new) {
              console.warn(e)
              this.startPrepareCompany('end');
            }
            if (resp.data.status == 'done') {
              // Костыльчик для старого сервиса
              // Скорей всего это решение идеально для интеграции -
              // со сторонними сервисами и более предпочтительное чем то что было до этого
              // Поскольку мы хотим знать где был последний раз клиент
              // и при следующем входе его туда обратно перебрасывать
              // то нам нужно запомнить в куки location
              // но сделать это так как мы сделали в новой админке не получится так как с
              // online.skyservice.pro у нас нету доступа к кукам .skyservice.online
              // по этому запоминаем куда мы перешли здесь в случае если это старый сервер
              if (e.company_server == "s-1") {
                let currentLocation = {
                  project: "online.skyservice.pro"
                };

                apiCookies.set("location", JSON.stringify(currentLocation), {expires: 180})


                try {
                  let iosData = {
                    location: currentLocation
                  }
                  window.webkit.messageHandlers.saveUserData.postMessage(JSON.stringify(iosData));
                } catch (err) {
                  //Не приложение ios
                }


              }

              if (e.company_server == "s-5") {
                let currentLocation = {
                  project: "online2.skyservice.pro"
                };

                apiCookies.set("location", JSON.stringify(currentLocation), {expires: 180})


                try {
                  let iosData = {
                    location: currentLocation
                  }
                  window.webkit.messageHandlers.saveUserData.postMessage(JSON.stringify(iosData));
                } catch (err) {
                  //Не приложение ios
                }

              }

              location.href = resp.data.data;
            } else if (resp.data.error == 'worker is inactive') {
              window.say.messageError(this.lang['youBlockedInComp']);
            } else {
              this.getData();
              window.say.messageError(this.lang['Error']);
            }
          })
        } catch (err) {
          window.say.messageError(this.lang['Error']);
        }
      }
    },
    acceptCompany(item) { // вызываем модалку принять/отклонить приглашение
      this.companyData = item;
      this.showAcceptInvite = !this.showAcceptInvite;
    },
    // Функция для установки пользовательских данных через Crisp
    setUserData(key, value) {
      try {
        if (value) {
          window.$crisp.push(["set", key, value]);
        }
      } catch (error) {
        // console.warn(error)
      }
    },
    getData() { // получаем данные по персоне
      try {
        apiList.getCompanies().then(resp => {
          if (resp.data.status == 'done') {
            if (resp.data) { // После получения данных о компании передаем их в чат
              if (webviewCheck() !== "android_webview") {

                const userData = resp.data.data.user;

                // Установка пользовательской электронной почты
                this.setUserData("user:email", userData.email);

                // Установка имени пользователя (если доступно, иначе используется значение по умолчанию)
                try {
                  const userName = userData.name ? userData.name : this.lang['withoutName'];
                  this.setUserData("user:nickname", userName);
                } catch (error) {
                  // console.error(error)
                }
                // Установка номера телефона
                this.setUserData("user:phone", userData.phone);

                // Установка страны пользователя
                this.setUserData("user:country", userData.country);

                // Установка идентификатора аккаунта
                this.setUserData("user:accountId", userData.accountId);
              }
            }
            this.form = resp.data.data;
            store.lang = this.form.lang;


            try {
              //Кто есть в списке тот и получает режим разработчика
              if (developers.includes(this.form.user.account_id)) {
                this.isDeveloper = true;
              }
            } catch (err) {
              console.error();
            }


            if (resp.data.data.user.country) {
              store.country = resp.data.data.user.country;
            }

            if (resp.data.data.user.IP) {
              store.IP = resp.data.data.user.IP;
            }

            //resp.data.data.user.account_id = "f2ab140d-f92c-47b2-8c64-b9dd3ab098d8"; //костыль временно для теста
            if (resp.data.data.user.account_id) {
              store.accountId = resp.data.data.user.account_id;
              //Подключение ВебСокета
              ws();
            }

            if (!this.form.user.name) {
              this.withoutName = true
              this.form.user.name = this.lang['withoutName']
            } else {
              this.withoutName = false
              this.form.user.name = resp.data.data.user.name
            }
            // добавляем в стор сразу рефералки
            store.ref = this.form.ref;
            // на всякий случай, вдруг юзер зашел через соц сеть с нового устройства, записываем его номер телефона
            if (this.form.user.phone != null) {
              localStorage.setItem('registration_phone', this.form.phone);
            }
            if (!this.form.user.have_password) {
              this.showAddPass = true
            }
          } else if (resp.data.error == "Lost connection to Redis server." || resp.data.error == "socket error on read socket") {
            if (document.referrer) {
              let status = confirm("Authorization temporary is unavailable. Go back to: " + document.referrer);
              if (status) {
                document.location.href = document.referrer;
              } else {
                this.gotoAuth();
              }
            } else {
              // в случае ошибки кикаем токен, если он имеется и редиректим на страницу логина
              this.gotoAuth();
            }
            //Если упал редис то не редиректим на авторизацию
          } else {
            // в случае ошибки кикаем токен, если он имеется и редиректим на страницу логина
            this.gotoAuth();
          }
        }).catch(err => {
          console.error(err)
          window.say.messageError(err);
        })
      } catch (err) {
        window.say.messageError(this.lang['Error']);
      }
    },
    gotoAuth() {
      //удаляем токен
      if (store.token) {
        apiCookies.set("token", '', {expires: -1});
        store.token = null;
      }
      document.location.href = 'https://auth.skyservice.online/';
    },
    changeCompaniesList() { // Включить сортировку списка компаний
      if (this.searchString.length > 0) {
        this.searchString = '';
      }
      this.sortList = true;
      //window.say.messageWarn("Режим сортування компаній. Перемістіть компанії в зручному для Вас порядку, та натисніть кнопку зберегти");
    },
    saveCompaniesList() { // Сохранить последовательность компаний
      this.sortList = false
      const companiesListSort = this.companiesList.map(e => e.company_id);
      apiList.sortCompanies(companiesListSort)
          .then(json => {
            if (json.error) {
              window.say.messageError(...json.error)
            }
          });
    },
    cancelChanges() { // Отменить сортировку компаний
      this.sortList = false
      this.getData();
    }
  },
  computed: {
    phrasesArray() {
      return [
        this.lang['preparingCompany1'],
        this.lang['preparingCompany2'],
        this.lang['preparingCompany3'],
        this.lang['preparingCompany4']
      ]
    },
    lang() {
      if (this.form.lang != '' && this.form.lang != null) {
        window.lang = store.langs[this.form.lang]()
      }
      return window.lang;
    },
    w() {
      return store.window.width;
    },
    companiesList() {
      if (this.searchString) {
        return jsSearch(this.form.companies, this.searchString, 'like', ['company_name', 'company_description', 'company_id'],);
      } else {
        return this.form.companies;
      }
    },
    searchText() {
      return this.lang["Search"] ? this.lang["Search"] : "";
    },
    dragOptions() { // опции для draggable
      return {
        animation: 250,
        disabled: !this.sortList,
        ghostClass: "ghost",
        delayOnTouchOnly: true, // задержка только для тача чтобы клик не срабатывал как драг
        delay: 250 // задержка
      };
    }
  }

}
</script>

<style scoped>
.flex-grow-1 {
  flex-grow: 1;
}

.companies {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.blurred {
  filter: blur(5px) brightness(50%) contrast(120%);
}

.d-flex {
  display: flex;
}

.p-absolute {
  position: absolute;
}

.hostApi {
  justify-content: center;
  align-items: center;
  gap: 5px
}

.hostApi input {
  margin: 0 !important;
}

.invites-div-desc {
  width: 85%;
  margin-top: 8px;
}

.company-invites-block {
  margin-bottom: 1rem;
  padding: 0 2px;
}

.noCompany-text {
  color: grey;
}

.icon-blocked {
  top: 5px;
  right: 5px;
  color: red;
  cursor: auto;
}

.icon-home {
  top: 5px;
  right: 5px;
  color: #647882;
  cursor: auto;
}

.text-div {
  float: left;
  margin-left: 10px;
  text-align: center;
  color: #525252;
}

.sortSave, .rollBack {
  margin-right: 5px;
}

.sortSave {
  background-color: #49b461;
  border-color: #28a745;
}

.sortSave:hover {
  background-color: #1e7e34;
  border-color: #1e7e34;
}

.rollBack {
  background-color: #f75656;
  border-color: #f75656;
}

.rollBack:hover {
  background-color: #d64545;
  border-color: #d64545;
}

.img-div {
  float: right;
  width: 20px;
}

.img-arrow {
  width: 14px;
  transform: rotate(270deg);
  margin-bottom: 2px;
  color: #525252;
}

.buttonCard {
  justify-content: flex-end;
}

.enter-company {
  background-color: transparent;
  border: 1px solid #e7e7e7;
  cursor: pointer;
}

/*.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  border-color: #343a40;
}

.btn-outline-dark:hover,
.btn-outline-dark:active,
.btn-outline-dark:focus {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover .text-div,
.btn-outline-dark:active .text-div,
.btn-outline-dark:hover .text-div {
  color: #fff;
}*/

.enter-company:hover,
.enter-company:focus,
.enter-company:active {
  background: #f2f2f2 !important;
  border: 1px solid #e7e7e7 !important;
}

.btn-info, .enter-company {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: right;
  border-radius: 20px;
  font-size: 14px;
  color: white;
  transition: background-color 0.3s ease;
}

.enter-company-blocked {
  width: 100%;
  text-align: right;
}

.p-style {
  color: #b7b6b6;
  font-size: 0.8rem;
}

.desc-div {
  width: 85%;
  margin-top: 8px;
}

.card {
  padding: 5px;
}

.company-search {
  position: relative;
  width: 300px;
  padding-top: 15px;
}

.company-block {
  flex-wrap: wrap;
  gap: 15px;
}

.col-md-4 {
  flex: 1 1 calc(33.33% - 15px);
  min-width: 350px;
  align-items: stretch;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
}

@media (min-width: 767px) {
  .col-md-4 {
    max-width: calc(33.33% - 15px);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .col-md-4 {
    flex: 1 1 calc(33.33% - 15px);
    min-width: 250px;
  }
}

.pos-style {
  flex-direction: column;
  width: 100%;
  background: #fff;
  border-radius: 0.5rem;
  padding: 15px;
  cursor: pointer;
  position: relative;
  box-shadow: 0 1px 12px rgba(0, 0, 0, .1);
  transition: box-shadow .2s ease, transform .2s ease, -webkit-box-shadow .2s ease, -webkit-transform .2s ease;
}

.pos-style.blocked {
  background-color: #f8d5d6;
}

.pos-style-developer {
  line-height: 1.2;
  min-height: 110px;
  padding: 10px;
  border-radius: 0.5rem;
  position: relative;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 1px 12px rgba(0, 0, 0, .1);
  background: #fffad6;
  border: 1px solid #dadada !important;
  transition: box-shadow .2s ease, transform .2s ease, -webkit-box-shadow .2s ease, -webkit-transform .2s ease;
}

@media (pointer: fine) {
  .pos-style:hover, .pos-style-developer:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, .2);
    transition: 0.3s ease-in-out;
  }
}

.clear-search {
  width: 30px;
  height: 30px;
  top: 20px;
  right: 0;
  cursor: pointer;
  color: #c9c9c9;
}

.h5-500 {
  line-height: 1.7;
  font-weight: 500;

}

.alert-warning {
  border-color: #f5db89;
  margin-left: -8px;
  margin-right: -8px;
}

.not-search-div {
  text-align: center;
}

.buttonStartComp {
  width: 220px;
  margin-right: 10px;
}

.logoDiv {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  padding-left: 3px;
  margin: 0 auto;
  font-size: 3rem;
  width: 3.5rem;
  color: green
}

.logoImg {
  height: 60px;
  width: 60px;
  margin-right: 10px;
}

.searchInput {
  border: none;
  border-bottom: 1px solid #c8c8c8;
  background: transparent;
}

.company_h {
  font-weight: bold;
  font-size: 18px;
}

.company_h span {
  color: rgb(223, 223, 223);
  font-weight: bold;
}

.description,
.company_h {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
}

.description input {
  background: transparent;
  border: 0;
  height: 20px;
  width: 100%;
}

.cat-svg {
  height: 100px;
  margin-bottom: 2rem;
}

.invites-accept {
  border: 1px solid rgb(243 219 142);
  background: #fff3cd;
}

.dialog_newdocument {
  width: 100%;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
  text-align: center;
  padding: 30px 0 30px 0;
  margin-bottom: 15px;
  cursor: pointer;
}

.main-div {
  //height: calc(100vh - 70px);
  //overflow-y: auto;
  padding: calc(60px + 1rem + 15px) 15px 70px;
}

/* Всі iPhone з вирізом (X, XS, 11 Pro, 12 Mini, 13 Mini, XR, 11, 12, 12 Pro, 13, 13 Pro, 14, 14 Pro, 14 Plus, 14 Pro Max) */
@media only screen and
(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), /* X, XS, 11 Pro, 12 Mini, 13 Mini */
(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), /* XS Max, 11 Pro Max */
(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), /* XR, 11 */
(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3), /* 12 Pro Max, 13 Pro Max, 14 Plus */
(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), /* 12, 12 Pro, 13, 13 Pro, 14 */
(device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3), /* 14 Pro, 15, 15 Pro, 16 */
(device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3), /* 14 Pro Max, 15 Pro Max, 15 Plus, 16 Plus */
(device-width: 402px) and (device-height: 874px) and (-webkit-device-pixel-ratio: 3), /* 16 Pro */
(device-width: 440px) and (device-height: 956px) and (-webkit-device-pixel-ratio: 3) /* 16 Pro Max */ {

  @media screen and (orientation: portrait) {

    .main-div {
      padding-bottom: 180px;
    }

    .app {
      padding-top: calc(110px + 1rem + 15px);
    }

    @supports (padding: env(safe-area-inset-top)) {
      .ios-class .main-div {
        padding-bottom: calc(env(safe-area-inset-top) + 60px) !important;
      }
    }
  }

  @media screen and (orientation: landscape) {
    @supports (padding: env(safe-area-inset-top)) {
      .ios-class .main-div {
        padding-bottom: calc(env(safe-area-inset-top) + 30px);
      }
    }
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1400px;
  }
}

.span-container {
  border-radius: 0.25rem;
  padding: 10px;
}

.add-company {
  color: #106090;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 1rem;
  font-weight: 500;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.add-company:hover {
  text-decoration: underline;
}

.ghost-container {
  margin-bottom: 1rem;
  flex-direction: column;
  align-items: center;
}

.ghost-text {
  padding-right: 10px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#person {
  background-color: #f8f8f8;
}

@media screen and (max-width: 768px) {
  .company-search {
    position: relative;
    width: 100%;
    padding-top: 15px;
  }

  .buttonStartComp {
    margin-bottom: 5px;
    margin-right: 0;
  }

  .btn.dropdown-toggle.btn-primary {
    max-width: 30vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ghost-container {
    padding-top: calc(110px + 1rem + 15px);
  }

  .ghost-text {
    padding-right: 0;
  }
}

.ghost {
  opacity: 0;
}

.blue-color {
  color: #106090;
}

.sortbutton {
  border: none;
  cursor: pointer;
  font-size: 12pt;
  background: transparent;
}

.editbutton {
  border: none;
  cursor: pointer;
  font-size: 12pt;
  background: transparent;
  margin: 0 !important;
  padding: 0 !important;
}

.sortbutton svg {
  color: #106090;
}

.closeStartDescription {
  top: 2px;
  right: 0;
  color: #856405;
  cursor: pointer;
  border: none;
  background: transparent;
}

.sortedMode {
  opacity: 0.6;
}

.sortedIcon {
  width: 60px;
  height: 60px;
  background: rgb(193 193 193 / 60%);
  top: calc(50% - 50px);
  border-radius: 50%;
  left: calc(50% - 30px);
}

.icon-red {
  color: red;
  font-size: 16px;
  margin-right: 5px;
}

.cancel-button {
  border: 0;
  background: transparent;
}

.form-control {
  border-radius: 0 !important;
}
</style>

