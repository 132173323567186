const lang = {
    "ToAccept": "Принять",
    "Reject": "Отклонить",
    "Password": "Пароль",
    "RepeatPassword": "Повторить пароль",
    "OldPassword": "Старый пароль",
    "MyCompany": "Мои компании",
    "Company": "Компания",
    "CreateNewCompany": "Создать новую компанию",
    "Сancel": "Отмена",
    "Save": "Сохранить",
    "GeneralInformation": "Общая информация",
    "CompanyName": "Название компании",
    "LogOut": "Выйти",
    "SignOutOfAccount": "Выйти из учетной записи",
    "ChangePassword": "Изменить пароль",
    "AddPassword": "Добавить пароль",
    "Language": "Язык",
    "ConfirmThisMail": "Ваш e-mail подтвержден",
    "Invites": "Приглашения",
    "AcceptInvitation": "Принять приглашение",
    "EMailOrPhone": "Телефон или почта",
    "Saved": "Сохранено",
    "Error": "Ошибка",
    "FillInAllTheFields": "Заполните все поля",
    "FirstCreateAnAccountPassword": "Для начала, создайте пароль аккаунта",
    "Settings": "Настройки",
    "Next": "Далее",
    "MinPassLength": "Пароль слишком короткий",
    "action": "Действие",
    "NewOwner": "Новый владелец",
    "UserMustBeRegistered": "Пользователь должен быть зарегистрирован",
    "LeaveTheCompany": "Покинуть компанию",
    "Confirm": "Подтвердить",
    "WrongFormat": "Неверный формат",
    "DeleteAccount": "Удалить аккаунт",
    "WrongPassword": "Неверный пароль",
    "PhoneAlreadyRegistered": "Телефон уже зарегистрирован",
    "EmailAlreadyRegistered": "Почта уже зарегистрирована",
    "Yes": "Да",
    "CreateACompany": "Создать компанию",
    "MyReferrals": "Мои рефералы",
    "Edit": "Изменить",
    "NewEmail": "Новая почта",
    "Proceed": "Продолжить",
    "IncorrectCaptcha": "Неправильная капча",
    "CaptchaIsNotValid": "Капча больше недействительна",
    "EnterCaptcha": "Введите капчу",
    "EnterSMS": "Введите СМС",
    "SMSCodeIsNoLongerValid": "СМС код уже не действителен",
    "SMSCodeIsIncorrect": "СМС код неверный",
    "MailNotVerifiedLinkTimedOut": "Почта не подтверждена, истекло время ссылки",
    "ReferralLink": "Реферальная ссылка",
    "Date": "Дата",
    "EarnWithUs": "Зарабатывайте с нами",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Рекомендуйте Skyservice POS вашим друзьям и получайте реальные деньги на свой счёт.",
    "HowItWorks": "Как это работает?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "Всё очень просто: Вы приводите клиентов в Skyservice POS, а мы делимся с Вами их платежами.",
    "Copied": "Скопировано",
    "TheMinimumWithdrawalAmountIs100USD": "*Минимальная сумма для вывода 100 USD",
    "AddEmail": "Добавить почту",
    "YouDontHaveCompanyYet": "У вас пока нет компании",
    "NoCompanyText": "Для начала работы с кассой необходимо создать компанию или принять приглашение от работодателя.",
    "ConfirmEmail": "На почту было отправлено письмо для подтверждения изменения",
    "description": "Описание",
    "NoDescriptionSpecified": "Нет описания",
    "DateCreate": "Дата создания:",
    "DelComp": "Удалить компанию",
    "Help": "Техподдержка",
    "Email": "Почта",
    "Phone": "Телефон",
    "SureAccDel": "Вы действительно хотите отвязать ваш аккаунт",
    "no": "Нет",
    "yes": "Да",
    "confirm": "Подтвердить",
    "cancel": "Отмена",
    "Search": "Поиск",
    "dateDelete": "Дата удаления:",
    "active": "Активный",
    "delete": "Удалён",
    "services": "Сервисы",
    "status": "Статус",
    "recover": "Восстановить",
    "restored": "Восстановлено",
    "restore": "Восстановление",
    "DoYouWantRestore": "Вы хотите восстановить кампанию",
    "deletedCompamies": "УДАЛЕННЫЕ КОМПАНИИ",
    "CompanyNotSearch": "Не найдено компаний по данным критериям",
    "writeCorrectlyName": "Введите имя пользвователя корректно",
    "addlogo": "Добавить логотип",
    "Logo": "Логотип",
    "nipmg": "Нажмите, или перетащите файл в это окно",
    "ErrorDownload": "Ошибка загрузки",
    "preview": "Предпросмотр",
    "onlyimg": "Только изображения",
    "imgisbig": "Изображение превышает предельный размер",
    "minimgsize": "Минимальный размер изображения",
    "Zagruzka...": "Загрузка...",
    "Youbrowserisold": "Ваш браузер не поддерживается, пожалуйста, используйте IE10 + или другие браузеры",
    "failZagruzhen": "Файл успешно загружен!",
    "zakrit": "Закрыть",
    "nazad": "Назад",
    "save": "Сохранить",
    "zagruzit_foto": "Загрузить фото",
    "clear": "Очистить",
    "logoIsAdd": "Логотип добавлен",
    "logoIsDel": "Логотип очищен",
    "editComp": "Редактировать компанию",
    "giveComp": "Передать компанию",
    "thisUserNorRegistered": "Такой пользователь не зарегестрирован",
    "cantTransferToYou": "Нельзя передавать свою компанию себе",
    "youNotOwner": "Вы не являетесь владельцем компании",
    "Ok": "Ок",
    "notYetCreateComp": "На данный момент создание новых компаний недоступно",
    "EnterToCompany": "Войти в компанию",
    "startDescription": "Приветствуем Вас в вашем персональном кабинете, войдите в компанию для начала работы",
    "closeStartDescription": "Закрыть",
    "inviteComp": "Приглашение в компанию",
    "visitDemo": "Посетить демо компанию",
    "makeUrChoice": "Сделайте выбор",
    "ImWorker": "Я наемный работник",
    "ImOwner": "Я работодатель, представитель работодателя",
    "needInviteComp": "Необходимо принять приглашение в существующую компанию",
    "needCreateComp": "Необходимо создать компанию для начала работы",
    "daysLeft": "Осталось дней",
    "cannotDel": "Удаление сейчас недоступно",
    "NoCompanyCreateText": "Для начала работы с кассой необходимо принять приглашение от работодателя.",
    "withoutName": "Без имени",
    "cropImageHint": "Нажмите или перетащите файл для загрузки",
    "cropImageNoSupported": "Браузер не поддерживается, используйте IE10+ или другие браузеры.",
    "cropImageSuccess": "Загрузка прошла успешно",
    "cropImageFail": "Загрузка не удалась",
    "cropImageOnlyImgNeed": "Только изображения",
    "cropImageOutOfSize": "Размер изображения превышает лимит",
    "cropImageLowestPx": "Минимальный размер изображения",
    "uReachedLimitComp": "Вы достигли лимита по кол-ву созданных компаний",
    "accAlreadyRegister": "Эта учётная запись уже привязана к другому аккаунту",
    "accNotRegister": "Не существует такой учётной записи",
    "areYourCountry": "это ваша страна?",
    "enterCorrectNumber": "Введите номер в международном формате",
    "attach": "Привязать",
    "UserEmailAlreadyExists": "Пользователь с такой почтой уже существует",
    "AccountAlreadyLinked": "Аккаунт уже привязан",
    "facebookAccIsAlreadyRegistered": "Этот Facebook аккаунт уже привязан к другой учетной записи Skyservice",
    "googleAccIsAlreadyRegistered": "Этот Google аккаунт уже привязан к другой учетной записи Skyservice",
    "telegramAccAlreadyRegister": "Этот Telegram уже привязана к другой учетной записи Skyservice",
    "companyBlocked": "Эта компания неактивна",
    "allSessions": "Показать все сесии",
    "activeSessions": "Активные сессии",
    "exitAllDevices": "Выйти на всех остальных устройствах",
    "currentSession": "Текущая сессия",
    "autoCloseSessions": "Автозавершение сессий",
    "nonActivePeriod": "Автоматически завершать если сессия не активна",
    "oneWeek": "1 неделя",
    "oneMonth": "1 месяц",
    "threeMonth": "3 месяца",
    "sixMonth": "6 месяцев",
    "terminate": "Завершить",
    "sessionDesc1": "Здесь перечислены все устройства, на которых выполнен вход в вашу учётную запись Skyservice. Вы можете выйти из учётной записи на каждом из них по отдельности или на всех сразу.",
    "sessionDesc2": "Если вы обнаружили в списке незнакомое устройство, немедленно выполните выход на этом устройстве и смените пароль от вашей учётной записи Skyservice.",
    "sureToTerminate": "Вы действительно хотите завершить все активные сессии?",
    "ip": "IP-адрес",
    "geoPos": "Геопозиция",
    "info": "Информация",
    "sessionDesc3": "Сеансы, с которых не будет никакой активности в течение этого периода, будут завершаться автоматически.",
    "terminatesSessions": "Завершение сеансов",
    "more3monthAgo": "Более трёх месяцев назад",
    "noData": "Нет данных",
    "goSettingsCompany": "Создаем вашу компанию, дайте нам минутку...",
    "preparingCompany1": "Создаем вашу компанию, пожалуйста, дайте нам минутку...",
    "preparingCompany2": "Готовим интерфейс для управления товарами и услугами...",
    "preparingCompany3": "Работаем над удобным учетом финансов...",
    "preparingCompany4": "Выполняем финальные настройки, почти готово...",
    "appleAccIsAlreadyRegistered": "Этот Apple ID уже привязан к другой учетной записи Skyservice",
    "notGetDataFromApple": "Не удалось получить данные от Apple",
    "changeTheOrder": "Поменять порядок компаний",
    "saveChangeOrder": "Сохранить",
    "cancelChangeOrder": "Отменить",
    "developerURL": "Ссылка разработчика"
}

export default function() {
    return lang;
}