<template>
  <div>
    <smallModal @escape="$emit('close')">
      <div v-if="havePassword" class="form-add-company">
        <h5>{{ lang["CreateACompany"] }}</h5>
        <hr />
        <div>
          <label for="name">{{ lang["CompanyName"] }}</label>
          <b-form-input
            v-model="form.company_name"
            autofocus
            :state="stateCompanyName"
            name="name"
          />
        </div>
        <div>
          <label for="description">{{ lang["description"] }}</label>
          <b-form-input
            v-model="form.company_description"
            :state="stateCompanyDesc"
            name="description"
          />
        </div>
        <hr />
        <template v-if="isShowServerSelect == true">
          <div>
          <label for="server">Server</label>
          <b-form-select v-model="form.server" :options="options"></b-form-select>
        </div>
        <hr />
        </template>
      </div>
      <div v-else class="firstCreate-div">
        <div class="center">
          <h5 class="firstCreate-h5">
            {{ lang["FirstCreateAnAccountPassword"] }}
          </h5>
        </div>
        <div class="go-settings">
          <span @click="goSettings">
            {{ lang["Settings"] }}
          </span>
        </div>
      </div>
      <template>
        <div class="buttons">
          <button
            class="btn btn-secondary"
            @click="$emit('close')"
          >
            {{ lang["Сancel"] }}
          </button>
          <button
            id="Button"
            class="btn btn-success"
            @click="showCheckPassModal"
          >
            {{ lang["Save"] }}
          </button>
        </div>
      </template>
    </smallModal>

    <checkpass
      v-if="showCheckPass"
      @close="showCheckPass = false"
      @checkPass="checkPass"
    ></checkpass>
  </div>
</template>

<script>
import { keyevent } from '@/main.js';
import { store } from "@/store.js";
import { errList } from "../js/CaptchaAndPassError";
import { regAPi } from "@/API.js";
import checkpass from "./checkPassword.vue";
import smallModal from "../modals/smallModal";

const apiList = new regAPi();
export default {
  props: ["havePassword"],
  components: {
    checkpass,
    smallModal,
  },
  data() {
    return {
      // imgUrl:'',
      showCheckPass: false,
      form: {
        company_name: "",
        city: "",
        date_of_create: "",
        company_description: "",
        server: ""
      },
      safety: null,
      options: [
          { value: null, text: 'default' },
          { value: 's-1', text: 'online.skyservice.pro' },
          { value: 's-2', text: 'xn-e28h.*.skyservice.online' },
          { value: 's-4', text: 'xn-l3h.*.skyservice.online' },
          { value: 's-5', text: 'xn-9i8h.*.skyservice.online' },
          { value: 's-6', text: 'xn-s19h.*.skyservice.online' },
          { value: 's-7', text: 'xn-4t8h.*.skyservice.online' },
          { value: 's-8', text: 'xn-3s9h.*.skyservice.online' },
          { value: 'dev', text: 'dev.*.skyservice.online [VPN or Hosts only]' },
          { value: 'staging', text: 'staging.*.skyservice.online' },
          { value: 'hotfix', text: 'hotfix.*.skyservice.online' },
      ]
    };
  },
  mounted() {
    if (this.havePassword) {
      keyevent.$off('enter');
      keyevent.$on('enter', () => this.showCheckPassModal())
    }  
  },
  methods: {
    goSettings() {
      this.$emit("close");
      this.$emit("openSettings");
    },

    // тут мы вызываем модалку для ввода пароля от аккаунта
    // изначально проверяем, что бы все поля были заполненны
    showCheckPassModal() {
      if (this.checkAllInput) {
        this.showCheckPass = true;
      } else {
        window.say.messageError(this.lang["FillInAllTheFields"]);
      }
    },
    // берем пароль аккаунта и вызываем метод сохранения
    checkPass(e) {
      this.safety = e;
      this.save();
    },
    save() {
      // еще раз проверяем аполненность полей, проверок много не бывает)
      if (this.checkAllInput) {
        try {
          // добавляем в форму токен
          this.form.token = store.token;
          apiList
            .addCompany({ form: this.form, ...this.safety })
            .then((resp) => {
              if (resp.data.status == "done") {
                window.say.message(this.lang["Saved"]);
                this.showCheckPass = false;
                this.$emit("close");
              } else if(resp.data.error == "creation of new companies is temporarily unavailable"){
                window.say.messageError(this.lang["notYetCreateComp"]);
                this.showCheckPass = false;
                this.$emit("close");
              } else if(resp.data.error[0].message == "Company creation limit reached"){
              window.say.messageError(this.lang["uReachedLimitComp"]);
              this.showCheckPass = false;
              this.$emit("close");
              } else {
                errList(resp.data.error[0]);
                console.log("nen");
              }
            })
            .finally(() => {
              store.disableButton = false;
            });
        } catch (err) {
          window.say.messageError(this.lang["Error"]);
        }
      } else {
        window.say.messageError(this.lang["FillInAllTheFields"]);
      }
    },
  },
  computed: {
    lang() {
      return window.lang;
    },
    isShowServerSelect(){
      if(this.form.company_description == "debug_server"){
        return true;
      }else{
        return false;
      }
    },
    stateCompanyDesc(){
      return this.form.company_description.length < 100 ? true : false;
    },
    stateCompanyName() {
      const reg = /[^\s]/;
      if (
        reg.test(this.form.company_name) &&
        this.form.company_name.length > 2 &&
        this.form.company_name.length < 75
      ) {
        return true;
      } else {
        return false;
      }
    },
    // проверка заполненности все полей формы
    checkAllInput() {
      let inputs;
      if (this.stateCompanyName) {
        inputs = true;
      } else {
        inputs = false;
      }
      return inputs;
    },
  },
};
</script>

<style scoped>
.center{
  text-align: center;
}
.firstCreate-div{
  padding: 15px;
}
.firstCreate-h5{
  color: gray;
}
.buttons{
    display: flex; 
    justify-content: space-between;
}
.btn-secondary, .btn-success{
  width: 49%;
}
#my-id > div {
  /** > .grecaptcha-badge  > div */
  height: 100px;
  position: relative !important;
}
.go-settings {
  text-align: center;
  color: #106090;
  text-decoration: underline;
  cursor: pointer;
  display: block;
  margin-top: 2rem;
}
</style>