<template>
    <div id="app" :class="isIOS ? 'ios-class':''">
        <div id="con">
            <loader v-if="loader" />
            <router-view></router-view>

            <!--Портал для диалоговых окон-->
            <portal-target name="dialog" style="position:absolute;" multiple />

            <!--Портал для modalok-->
            <portal-target name="modal" style="position:absolute;" />

            <notifications group="foo" position="bottom right" style="z-index: 99999999999" />

            <template v-if="show_settings">
                <personsettings @close="closeSettings" />
            </template>
        </div>
    </div>
</template>

<script>
import { handleResize } from "@/main.js";
import { store } from './store';
import personsettings from '@/components/settings/PersonSettings';
import { androidFixMono } from '@/components/js/androidFixMono.js';
import { webviewCheck } from "@/components/js/webviewCheck.js";
import loader from './components/Loader';
import Cookies from 'js-cookie';

// создаем апи куков с настройкой дэфолтного домена для продакшена
let apiCookies = Cookies;

if (process.env.NODE_ENV == 'production') {
   apiCookies = Cookies.withAttributes({domain: ".skyservice.online"})
}

export default {
    name: 'App',
    components: {
        loader,
        personsettings
    },
    data() {
        return {
            time: 0,
            show_settings: false,
            overlayVisible: false,
        }
    },
    beforeCreate() {
        // Перехват token из админки
        let accountSettings = null;

        try{
            //ES2015
            const inParams = new URLSearchParams(location.search);

            accountSettings = inParams.get('accountSettings');
        }catch(err){
            //ES6
            try{
                accountSettings = this.getUrlParams(window.location.search)["accountSettings"];
            }catch(err){
                console.log("Не вдалось отримати accountSettings")
            }

        }

        try {
            store.token = Cookies.get("token");

            // if(token) {
            //     //разавторизируем пользователя и кидаем на форму авторизации
            //     //localStorage.removeItem('token');
            //     apiCookies.set("token", '', {expires: -1});
            //     this.$router.push('/');
            // }

            if(accountSettings){
                // Открытие настроек аккаунта
                localStorage.setItem('show_settings_modal', true);
            }
        }catch(err) {
            console.error(err);
        }
    },
    created() {
        try{
            //showStatusBar iphone
            //window.webkit.messageHandlers.showStatusBar.postMessage(""); //баг на ipad. залазить годинник на меню
        }catch(err){
            console.error("iPhone not available")
        }

        androidFixMono("con")
        store.window.width = window.innerWidth;
        store.window.height = window.innerHeight;
    
        window.addEventListener('resize', this.handleResize);
        this.getRef();
        
        let currentLocation = {
            project:  "cabinet.skyservice.online"
        };

        apiCookies.set("location", JSON.stringify(currentLocation), { expires: 180 })


        try{
            let iosData = {
                location:currentLocation
            }
            window.webkit.messageHandlers.saveUserData.postMessage(JSON.stringify(iosData));
        }catch(err){
            //не приложение ios
        }


    },
    mounted() {
        androidFixMono("con")
        if(localStorage.getItem('show_settings_modal')) {
            this.show_settings = true;
        }
    },
    methods: {
        getUrlParams(search) {
            //ES6 get url params
            const hashes = search.slice(search.indexOf('?') + 1).split('&')
            const params = {}
            hashes.map(hash => {
                const [key, val] = hash.split('=')
                params[key] = decodeURIComponent(val)
            })
            return params
        },
        handleResize() {
            androidFixMono("con")
            store.window.width = window.innerWidth;
            store.window.height = window.innerHeight;
            if (this.time) clearTimeout(this.time);

            this.time = setTimeout(() => {
                handleResize.$emit('handleResize');
            }, 500);
        },
         // записываем ключ с реферальной ссылки
        getRef() {
            const url = window.location.href;
            const linkParams = (new URL(url)).searchParams; 
            // ретерним объект в котором помещаем выдранные из ссылки значения
            const ref_id = linkParams.get('ref');
            // проверяем не является ли null переменная, если в ней что то есть, тогда делаем запись в локалсторедж
            if (ref_id != null) {
                localStorage.setItem('ref_id', ref_id);
            }
        },
        closeSettings() {
            this.show_settings = false;
            localStorage.removeItem('show_settings_modal');
        },
        createOverlay() {
            this.overlay = document.createElement("div");
            this.overlay.style.position = "fixed";
            this.overlay.style.top = "0";
            this.overlay.style.left = "0";
            this.overlay.style.width = "100%";
            this.overlay.style.height = "100%";
            this.overlay.style.zIndex = "9999";
            document.body.appendChild(this.overlay);
        },
        removeOverlay() {
            if (this.overlay && this.overlay.parentNode) {
            this.overlay.parentNode.removeChild(this.overlay);
            }
        }
    },
    computed: {
        isIOS(){
            if(webviewCheck() == "ios_webview"){
                return true;
            }else{
                return false;
            } 
        },
        loader() {
            return store.loader;
        },
        lang() {
            return window.lang;
        },
    },
    watch: {
        loader(val) {
            if (val) {
                this.overlayVisible = true;
            } else {
                this.overlayVisible = false;
            }
        },
        overlayVisible(val) {
            if (val) {
                this.createOverlay();
            } else {
                this.removeOverlay();
            }
        }
    },
}
</script>

<style>
    /*Штука что б через js определить что есть выкушеный дисплей*/
    :root {
        --sat: env(safe-area-inset-top);
        --sar: env(safe-area-inset-right);
        --sab: env(safe-area-inset-bottom);
        --sal: env(safe-area-inset-left);
    }

    html {
        overflow: hidden;
    }
    body {
        padding: 0;
        margin: 0;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow: auto;
        height: 100vh;
        //background: #edf4f7;
        background: #f8f8f8;
        color:#3a484e;
    }
    input {
        margin-bottom: 1rem !important;
    }
    input:focus, button:focus, select:focus {
        outline: none !important;
        border-color: none !important;
        box-shadow: none !important;
    }
    .buttonitem {
        white-space: nowrap; /* Отменяем перенос текста */
        overflow: hidden; /* Обрезаем содержимое */
        text-overflow: ellipsis; /* Многоточие */
        width: 100%;
    }
    .buttonitem button{
        padding: 8px 3px;
    }
    .buttonitem:not(:first-child) {
        padding: 0 0 0 10px;
    }
    #con {
        background-color: #3693cf;
    }

    /*Только айфон 10, 11 и тд*/
    @media only screen and 
    (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3), /*X, XS, 11pro, 12 Mini, 13 mini*/
    (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 3), /*XS Max, 11 Pro Max*/
    (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 2), /*XR, 11*/
    (device-width : 428px) and (device-height : 926px) and (-webkit-device-pixel-ratio : 3), /*12 Pro Max. 13 pro max, 14 plus*/
    (device-width : 390px) and (device-height : 844px) and (-webkit-device-pixel-ratio : 3), /*12, 12 Pro, 13, 13 pro, 14*/
    (device-width : 393px) and (device-height : 852px) and (-webkit-device-pixel-ratio : 3), /*14 pro*/ 
    (device-width : 430px) and (device-height : 932px) and (-webkit-device-pixel-ratio : 3) /*14 pro max*/ 
    {

        @media screen and (orientation: portrait) { 
            .ios-class #headerContiner-white { 
                padding-top: env(safe-area-inset-top) !important;    
                height: calc(env(safe-area-inset-top) + 60px) !important;      
            }
            .ios-class .content {
                padding-top:calc(env(safe-area-inset-top) - 20px) !important;  
                padding-bottom:10px;
            }
        }
        @media screen and (orientation: landscape) {    

        }
    }

/*Для модалки выбора типа аккаунта (чекбокс создать компанию), из-за scope помещаем сюда */
    .createCompanyCheckbox .custom-control-label::before {
        border-color: gray !important;
        background-color: gray !important;
    }
</style>
